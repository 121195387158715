import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-none d-xl-block font-size-xl-lg font-weight-light" }
const _hoisted_2 = { class: "nav justify-content-end nav-header-main flex-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LuTopMenuItem = _resolveComponent("LuTopMenuItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (menuItem, index) => {
        return (_openBlock(), _createBlock(_component_LuTopMenuItem, {
          key: menuItem.id,
          item: menuItem,
          "last-item": index + 1 === _ctx.menu.length
        }, null, 8, ["item", "last-item"]))
      }), 128))
    ])
  ]))
}