import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.elementType), {
    href: _ctx.href ? _ctx.href : null,
    class: _normalizeClass(["badge", [_ctx.level ? _ctx.c_level : '', _ctx.rounded ? 'badge-pill' : '']]),
    style: _normalizeStyle({
      color: _ctx.color ? _ctx.color : null,
      'background-color': _ctx.backgroundColor ? _ctx.backgroundColor : null,
    })
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["href", "class", "style"]))
}