
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The search value
     */
    value: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    /**
     * If placeholder for the search input
     */
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * Aria label for the search input
     */
    ariaLabel: {
      type: String as PropType<string>,
      required: false,
    },
  },
  methods: {
    setFocus(): void {
      this.$refs.searchInput.focus();
    },
    onInput(searchPhrase: string): void {
      this.$emit("searchChanged", searchPhrase);
    },
  },
})
export default class Search extends Vue {}
