import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-none d-xl-block col-xl-3 collapse" }
const _hoisted_2 = { class: "nav-accordion nav-collapse nav-undecorated" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LuLeftMenuItem = _resolveComponent("LuLeftMenuItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
        return (_openBlock(), _createBlock(_component_LuLeftMenuItem, {
          key: item.id,
          item: item
        }, null, 8, ["item"]))
      }), 128))
    ])
  ]))
}