
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import Backdrop from "@/components/Backdrop.vue";

@Options({
  components: {
    Backdrop,
  },
  props: {
    allowCloseOutside: { type: Boolean as PropType<boolean>, default: false },
  },
  data: () => ({
    /**
     * If the modal is shown
     */
    shown: false,
  }),
  methods: {
    /**
     * Open the modal
     */
    open(): void {
      this.shown = true;
      this.$emit("open");
    },
    /**
     * Close the modal
     */
    close(): void {
      this.shown = false;
      this.$emit("close");
    },
    /**
     * Gets called when student clicks the modal/backdrop.
     * Close the modal if clicked outside modal
     */
    onClick(): void {
      // Make sure the student didn´t click the modal
      if (this.allowCloseOutside) {
        this.close();
      }
    },
  },
})
export default class Modal extends Vue {}
