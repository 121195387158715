<template>
  <div class="container pt-2 pt-lg-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb m-0 p-0 mb-0 font-size-sm bg-transparent">
        <li
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.path"
          class="breadcrumb-item text-truncate mb-0"
        >
          <router-link :to="breadcrumb.path">
            {{ breadcrumb.title }}
          </router-link>
        </li>
        <li
          v-if="currentPage.length > 0"
          class="breadcrumb-item text-truncate mb-0 active"
          aria-current="page"
        >
          {{ currentPage }}
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  data: () => ({
    breadcrumbs: [],
    currentPage: "",
  }),
  computed: {
    /**
     * The currently chosen locale
     */
    locale() {
      return this.$i18n.locale;
    },
    /**
     * Check if the user has entitlements "Employee" or "Tuition fees administratör"
     */
    isEmployeeOrTuitionAdmin() {
      const currentUser = this.$store.state.currentUser;
      return (
        currentUser &&
        (currentUser.entitlements.includes("Employee") ||
          currentUser.entitlements.includes("Tuition fees administratör"))
      );
    },
  },
  methods: {
    /*
     * Generates a list of breadcrumbs for the current page
     */
    generateBreadcrumbs() {
      const breadcrumbs = [];
      const metaData = this.$route.meta;

      if (metaData.breadcrumbs) {
        metaData.breadcrumbs.forEach((breadcrumb) => {
          breadcrumbs.push({
            path: breadcrumb.path,
            title: this.$t(breadcrumb.title),
          });
        });
      }
      if (this.isEmployeeOrTuitionAdmin && breadcrumbs.length > 0) {
        breadcrumbs[0] = {
          path: { name: "EmployeeHome" },
          title: this.$t("shared.routes.employee-home"),
        };
      }

      this.currentPage =
        breadcrumbs.length > 0 ? this.$t(metaData.titleKey) : "";
      this.breadcrumbs = breadcrumbs;
    },
  },
  watch: {
    $route() {
      this.generateBreadcrumbs();
    },
    locale() {
      this.generateBreadcrumbs();
    },
  },
  mounted() {
    this.generateBreadcrumbs();
  },
})
export default class BreadCrumbs extends Vue {}
</script>
