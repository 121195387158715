
import LuNavbarItem from "@/components/Lu/LuNavbarItem.vue";
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import type { IMenuItem } from "@/interfaces/IMenuItem";

@Options({
  components: {
    LuNavbarItem,
  },
  props: {
    /**
     * The items to show in navbar
     */
    menu: { type: Array as PropType<IMenuItem[]>, required: true },
  },
})
export default class LuNavbar extends Vue {}
