import JsonHelper from "@/helpers/json-helper";

class SessionStorageService {
  /**
   * The function `setItem` stores data in sessionStorage, converting non-string data to JSON before saving.
   * @param {string} key - The `key` parameter is a string that represents the key under which the data will be stored in the sessionStorage
   * @param {unknown} data - The `data` parameter in the `setItem` function can be of type `string` or any other data type. If the `data`
   * is a string, it is directly stored in the sessionStorage under the specified `key`. If the `data` is not a string, it is first
   * converted
   * @returns The `setItem` function returns a boolean value. It returns `true` if the data was successfully stored in the sessionStorage,
   * and `false` if an error occurred during the process.
   */
  setItem(key: string, data: unknown): boolean {
    try {
      if (typeof data === "string") {
        sessionStorage.setItem(key, data);
      } else {
        sessionStorage.setItem(key, JSON.stringify(data));
      }
      return true;
    } catch (e) {
      console.error("sessionStorageService.setItem ~ error", e);
      return false;
    }
  }

  /**
   * The function `getItem` retrieves data from sessionStorage, parsing it as JSON if valid, and returning it as the specified type or
   * undefined.
   * @param {string} key - The `key` parameter is a string that represents the key used to retrieve data from the sessionStorage.
   * @returns The `getItem` function returns a value of type `T` or `undefined`.
   */
  getItem<T>(key: string): T | undefined {
    if (key) {
      const storageData = sessionStorage.getItem(key);
      if (storageData) {
        try {
          if (JsonHelper.isValidJSON(storageData)) {
            const data = JSON.parse(storageData);
            return data;
          } else {
            return storageData as unknown as T;
          }
        } catch (e) {
          console.error("sessionStorageService.getItem ~ error", e);
          return undefined;
        }
      }
    }
    return undefined;
  }

  /**
   * The `deleteItem` function removes an item from the session storage based on the provided key.
   * @param {string} key - The `key` parameter in the `deleteItem` function is a string that represents
   * the key of the item you want to remove from the sessionStorage.
   */
  deleteItem(key: string) {
    sessionStorage.removeItem(key);
  }

  /**
   * The function `keyExists` checks if a key exists in the `sessionStorage` object in TypeScript.
   * @param {string} key - The `keyExists` function takes a `key` parameter, which is a string representing the key that you want to check
   * for existence in the `sessionStorage` object.
   * @returns The function `keyExists` is returning a boolean value, which indicates whether the key exists in the `sessionStorage` object.
   */
  keyExists(key: string): boolean {
    return Object.prototype.hasOwnProperty.call(sessionStorage, key);
  }
}

export default new SessionStorageService();
