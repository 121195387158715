import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "nav align-items-center justify-content-end" }
const _hoisted_2 = { class: "mobile-nav mobile-nav-root border-0 nav-collapse font-size-sm nav-undecorated" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LuHeaderAuthButtons = _resolveComponent("LuHeaderAuthButtons")!
  const _component_LuNavMobileItem = _resolveComponent("LuNavMobileItem")!
  const _component_LuNavMobileItemChangeLanguage = _resolveComponent("LuNavMobileItemChangeLanguage")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, { ref: "modal" }, {
    header: _withCtx(() => [
      _createElementVNode("nav", _hoisted_1, [
        _createVNode(_component_LuHeaderAuthButtons)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
          return (_openBlock(), _createBlock(_component_LuNavMobileItem, {
            key: item.id,
            item: item,
            onLinkSelected: _ctx.close
          }, null, 8, ["item", "onLinkSelected"]))
        }), 128)),
        _createVNode(_component_LuNavMobileItemChangeLanguage, { onLinkSelected: _ctx.close }, null, 8, ["onLinkSelected"])
      ])
    ]),
    _: 1
  }, 512))
}