import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.item.path || _ctx.item.name)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: {
      ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
      ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
    },
        class: _normalizeClass(["dropdown-item", { active: _ctx.active }])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 1)
        ]),
        _: 1
      }, 8, ["to", "class"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: _ctx.item.url,
        class: _normalizeClass(["dropdown-item", { active: _ctx.active }])
      }, _toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 11, _hoisted_1))
}