import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-dark d-none d-lg-block" }
const _hoisted_2 = { class: "container-fluid mw-xl py-0" }
const _hoisted_3 = { class: "navbar navbar-expand-md navbar-dark bg-dark font-size-sm p-0 text-white" }
const _hoisted_4 = {
  id: "navbarSupportedContent",
  class: "collapse navbar-collapse nmx-2"
}
const _hoisted_5 = { class: "navbar-nav flex-row ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LuNavbarItem = _resolveComponent("LuNavbarItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
              return (_openBlock(), _createBlock(_component_LuNavbarItem, {
                key: item.id,
                item: item
              }, null, 8, ["item"]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}