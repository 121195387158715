import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30bbf18f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mobile-nav-item"
}
const _hoisted_2 = { class: "mobile-nav-container" }
const _hoisted_3 = ["data-target", "aria-expanded", "aria-controls"]
const _hoisted_4 = { class: "collapse-show" }
const _hoisted_5 = { class: "collapse-hide" }
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  key: 1,
  class: "mobile-nav-item"
}
const _hoisted_8 = {
  key: 2,
  class: "mobile-nav-item"
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_LuNavMobileItem = _resolveComponent("LuNavMobileItem", true)!

  return (_ctx.item.children)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.item.path || _ctx.item.name)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {
          ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
          ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
        },
                class: "nav-link d-flex",
                "data-dismiss": "modal"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text) + " ", 1),
                  _createVNode(_component_Badge, {
                    class: "badge ml-3",
                    rounded: true,
                    backgroundColor: "crimson"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.item.badge), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: "#",
                class: "nav-link d-flex",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleExpanded && _ctx.toggleExpanded(...args)), ["prevent"]))
              }, [
                _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text) + " ", 1),
                _createVNode(_component_Badge, {
                  class: "badge ml-3",
                  rounded: true,
                  backgroundColor: "crimson"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.item.badge), 1)
                  ]),
                  _: 1
                })
              ])),
          _createElementVNode("div", {
            class: _normalizeClass(["mobile-nav-toggle", [_ctx.expanded ? '' : 'collapsed']]),
            "data-target": '#sm-' + _ctx.item.id,
            "aria-expanded": _ctx.expanded,
            "aria-controls": 'sm-' + _ctx.item.id,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleExpanded && _ctx.toggleExpanded(...args))),
            role: "button"
          }, [
            _withDirectives(_createElementVNode("span", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                class: "fa-lg",
                icon: 'plus-circle'
              })
            ], 512), [
              [_vShow, !_ctx.expanded]
            ]),
            _withDirectives(_createElementVNode("span", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                class: "fa-lg",
                icon: 'minus-circle'
              })
            ], 512), [
              [_vShow, _ctx.expanded]
            ])
          ], 10, _hoisted_3)
        ]),
        _createVNode(_Transition, { name: "slide" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("ul", {
              id: 'sm-' + _ctx.item.id,
              class: "mobile-nav collapse show"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (subItem) => {
                return (_openBlock(), _createBlock(_component_LuNavMobileItem, {
                  key: subItem.id,
                  item: subItem,
                  onLinkSelected: _ctx.childSelected
                }, null, 8, ["item", "onLinkSelected"]))
              }), 128))
            ], 8, _hoisted_6), [
              [_vShow, _ctx.expanded]
            ])
          ]),
          _: 1
        })
      ]))
    : (_ctx.item.path || _ctx.item.name)
      ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
          _createVNode(_component_router_link, {
            to: {
        ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
        ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
      },
            class: "nav-link d-flex",
            "data-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text) + " ", 1),
              _createVNode(_component_Badge, {
                class: "badge ml-3",
                rounded: true,
                backgroundColor: "crimson"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.badge), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : (_openBlock(), _createElementBlock("li", _hoisted_8, [
          _createElementVNode("a", {
            href: _ctx.item.url,
            class: "nav-link d-flex"
          }, [
            _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text) + " ", 1),
            _createVNode(_component_Badge, {
              class: "badge ml-3",
              rounded: true,
              backgroundColor: "crimson a"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.badge), 1)
              ]),
              _: 1
            })
          ], 8, _hoisted_9)
        ]))
}