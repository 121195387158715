export default {
  "shared": {
    "lunds-university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunds University"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "courseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course code"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
    "ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ended"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "editCoordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit coordinator"])},
    "editSupportDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit support date"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "confirm-delete-thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("item")), "?"])},
    "confirm-removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm removal"])},
    "confirm-remove-thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove ", _interpolate(_named("item")), "?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "remove_thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("thing"))])},
    "enable_thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enable ", _interpolate(_named("thing"))])},
    "disable_thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Disable ", _interpolate(_named("thing"))])},
    "no-things": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No ", _interpolate(_named("things"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "back-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to overview"])},
    "back-previous-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to previous page"])},
    "go-home-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to home page"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "accept-policy-full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms and conditions"])},
    "scroll-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll to top"])},
    "saveSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit successfully saved"])},
    "saveUnsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately something went wrong and the changes were not saved"])},
    "search-and-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search and Filter"])},
    "routes": {
      "note-taker-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taker overview"])},
      "note-taker-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taker details"])},
      "student-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student details"])},
      "note-taking-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register interest as note-taker / Share notes"])},
      "mentorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentorship"])},
      "notetaking-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taking Support"])},
      "tuition-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition fees"])},
      "repayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repayment"])},
      "suspension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspension"])},
      "postponement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postponement"])},
      "add-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Student"])},
      "student-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])},
      "employee-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page employee"])},
      "course-occurrence-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course instance overview"])},
      "course-occurrence-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course instance details"])},
      "student-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student overview"])},
      "offer-response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taking Support Response"])},
      "share-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share notes"])},
      "mirror-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mirror user"])}
    },
    "form": {
      "date-picker": {
        "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
        "increment-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase second"])},
        "increment-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase minute"])},
        "increment-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase hour"])},
        "decrement-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease second"])},
        "decrement-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease minute"])},
        "decrement-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease hour"])},
        "next-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next month"])},
        "prev-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous month"])}
      }
    },
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Portal"])},
    "coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinator"])},
    "proposedNoteTakersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "popup-error": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oops ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately something went wrong, please try again later."])}
    }
  },
  "header": {
    "show-hide-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show/Hide search"])},
    "show-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show menu"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen"])},
    "select-locale": {
      "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra språk till svenska"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svenska"])}
    }
  },
  "main": {
    "page-manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page manager"])}
  },
  "footer": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortcuts"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
  },
  "unauthorized": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It appears that you don't have permission to access this page. Please make sure you're authorized to view this content. If you think you should be able to view this page, please contact us."])}
  },
  "not-found": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for could not be found. If you think you should be able to view this page, please contact us."])}
  },
  "modal": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close modal"])}
  },
  "person": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "personalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Id"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "telephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Number"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinator"])},
    "grantedSupportDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granted support until"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "affiliation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eduPersonAffiliation"])},
    "primaryAffiliation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eduPersonPrimaryAffiliation"])},
    "lu-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LU Account"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])}
  },
  "note-taker-details": {
    "back-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to overview"])},
    "checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist"])},
    "administration-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "enter-new-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a new note here"])},
    "note-taker-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NT"])}
  },
  "student-details": {
    "saveSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit successfully saved"])},
    "saveUnsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately something went wrong and the changes were not saved"])},
    "no-coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No coordinators"])},
    "edit-btn-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit coordinator and/or granted support date"])},
    "activeNoteTaker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It is no longer possible to update this student<br />", _interpolate(_named("full_name")), " now has at least one active assignment or an active registration of interest as a note-taker"])}
  },
  "note-taker-list": {
    "show-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show students"])},
    "export-fee-basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Basis"])},
    "export-fee-accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Accounting"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "choose-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Date"])},
    "choose-date-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you specify a date, only data from this date forward and everything new (not previously exported) will be exported, otherwise only everything new"])}
  },
  "student-list": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support expired"])}
  },
  "note-taking-request": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register interest as note-taker / Share notes"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>On this page, you can register interest in becoming a note-taker for the courses in which you are registered, as well as sharing notes for the courses you have been assigned to as a note-taker.</p><p>To register interest as a note-taker, click on the checkbox beside the courses you are interested in and then click the <span style='font-weight:600;'>Save</span> button. The button is called <span style='font-weight:600;'>Update</span> if you have previously registered your interest.</p><p>To share notes, click the <span style='font-weight:600;'>Share notes</span> button to the right of the courses to which you have been assigned as a note-taker.</p>"])},
    "info-about-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about you"])},
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
    "choose-campus-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which campus are you studying at?"])},
    "choose-campus-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a campus"])},
    "choose-faculty-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which faculty do you belong to?"])},
    "choose-faculty-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a faculty"])},
    "choose-courses-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the courses you wish to provide note-taking support for"])},
    "edit-information-ladok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is your information incorrect? Edit it in"])},
    "add-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add student"])},
    "why-add-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a student if there is someone special you want to support."])},
    "send-application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send application"])},
    "alert-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must choose at least one course"])},
    "campuses": {
      "helsingborg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Helsingborg"])},
      "lund-malmo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Lund or Malmö"])}
    },
    "popup-success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thank you ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thank you for your registration. A confirmation has been sent to your email (", _interpolate(_named("email")), ")."])},
      "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your changes have been saved."])}
    },
    "cannot-deregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because you have an active assignment as note-taking support for this course, you cannot deregister from it"])},
    "cannot-reregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since your previous assignment as note-taking support for this course ended prematurely, you cannot register again"])},
    "completed-course": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This course ended '", _interpolate(_named("date")), "'"])},
    "spring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spring"])},
    "autumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autumn"])},
    "popup-not-possible": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sorry ", _interpolate(_named("name"))])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot be note-taking support "])},
      "student-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["while being registered to receive notes."])},
      "interim-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if you have a temporary social security number."])}
    }
  },
  "offerresponse": {
    "popup-accepted": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thank you ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for accepting the assignment as note support."])}
    },
    "popup-declined": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment declined"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The assignment as note support has been declined"])}
    }
  },
  "pagination": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "goto-previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to previous page"])},
    "goto-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to next page"])},
    "goto": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to page ", _interpolate(_named("page"))])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current page"])}
  },
  "form": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filter-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filter"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])}
  },
  "note-taker-status": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])}
  },
  "table": {
    "showingRowsOf": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("start")), "-", _interpolate(_named("end")), " of ", _interpolate(_named("total"))])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])}
  },
  "add-student": {
    "personalIdFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 numbers, or with hyphen"])},
    "studentExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specified social security number is already in the system"])},
    "studentDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specified social security number doesn't exist in Ladok"])},
    "saveSuccessful": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("full_name")), " is added to the list of students"])},
    "saveUnsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately something went wrong and the student was not saved"])},
    "grantedSupportUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granted support until"])},
    "activeNoteTaker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The person is already registered!<br />", _interpolate(_named("full_name")), " has at least one active assignment or an active registration of interest as a note-taker"])},
    "later-start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The courses below which are highlighted have a start date which is later than the granted support until date that you have entered."])}
  },
  "mandatoryField": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is mandatory"])}
  },
  "course-occurrence": {
    "occurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Instance"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name"])},
    "courseStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course start"])},
    "courseEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course end"])},
    "studentCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "noteTakerCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-takers"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "noteTakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-takers"])},
    "proposedNoteTakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-takers with interest"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CI"])},
    "coordinatorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinator CI"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "proposedNoteTakerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "edit-btn-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit coordinator"])},
    "statusText": {
      "Green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taker is assigned for this course instance"])},
      "Yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested note-taker is available for this course instance"])},
      "Orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More note-takers are needed for this course instance"])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taker is needed for this course instance"])},
      "Grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neither need nor interested note-taker is available for this course instance"])}
    },
    "remove-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove student"])},
    "confirm-removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm end of assignment"])},
    "confirm-remove-note-taker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to end the assignment for ", _interpolate(_named("name")), "?"])},
    "assignment": {
      "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
      "assignment-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment start"])},
      "assignment-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment end"])},
      "endAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End assignment"])}
    },
    "offer": {
      "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
      "canceledOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled offer"])},
      "sendOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send offer"])},
      "confirmSendOffer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to send an offer to ", _interpolate(_named("name"))])},
      "cancelOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End offer"])},
      "confirmCancelOffer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to cancel the offer for ", _interpolate(_named("name"))])},
      "offerSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer sent"])},
      "offerCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer canceled"])},
      "offer-response-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer of assignment as note-taking support"])},
      "offer-response-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have received an offer as note-taking support for a course instance. Make your choice below whether you accept the assignment or not."])},
      "offer-information-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the assignment"])},
      "policy-required-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before accepting the offer as note-taking support, you must agree to the "])},
      "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])}
    },
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
    "unavailable-reason": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It seems that the course instance '", _interpolate(_named("occurrence")), "' has been removed from Ladok."])},
    "uploads": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploads"])},
      "note-taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taker"])},
      "number-of-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of files"])},
      "latest-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest upload"])}
    }
  },
  "share-notes": {
    "page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share notes for course instance"])},
    "note-taking-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taking instance date"])},
    "note-taking-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-taking instance time"])},
    "select-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select note file"])},
    "file-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file size"])},
    "share-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share notes"])},
    "popup-success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thank you ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for uploading your notes."])}
    }
  },
  "file-upload": {
    "choose-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose File"])},
    "change-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change File"])},
    "no-file-chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file chosen"])},
    "max-file-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max file size"])},
    "permitted-file-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitted file types"])},
    "file-size-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size must be less than"])},
    "file-type-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File must be of the following type(s)"])}
  },
  "tuition-fees": {
    "bank-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])},
    "card-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card payment"])},
    "mirror-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mirror user"])},
    "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user"])},
    "search-term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search term"])},
    "search-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personal ID (12 digits), LU account or name"])},
    "search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
    "minimum-char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at least 2 characters"])},
    "no-search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your search returned no results"])},
    "mirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mirror"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "mirroring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently mirroring"])},
    "stop-mirroring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop mirroring"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Response"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unkown"])}
  },
  "student-portal-footer": {
    "column-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])}
    }
  },
  "tuition-fees-footer": {
    "column-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about tuition fees"])}
    },
    "column-2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
    },
    "column-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortcuts"])}
    }
  },
  "maintenance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Upgrade"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System upgrade in progress"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A system upgrade of the Student Portal is currently in progress.<br/>We apologize for any inconvenience this may cause. Please try again later."])}
  }
}