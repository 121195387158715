class JsonHelper {
  isValidJSON(data: string): boolean {
    try {
      const parsed = JSON.parse(data);
      if (parsed && typeof parsed === "object") {
        return true;
      }
    } catch {
      return false;
    }
    return false;
  }
}

export default new JsonHelper();
