import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column min-vh-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LuSpinner = _resolveComponent("LuSpinner")!
  const _component_Backdrop = _resolveComponent("Backdrop")!
  const _component_LuHeader = _resolveComponent("LuHeader")!
  const _component_BreadCrumbs = _resolveComponent("BreadCrumbs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LuMain = _resolveComponent("LuMain")!
  const _component_LuFooter = _resolveComponent("LuFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showSpinner)
      ? (_openBlock(), _createBlock(_component_Backdrop, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_LuSpinner, {
              class: "spinner",
              size: 3
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LuHeader, {
        navbarmenu: null,
        topmenu: _ctx.getMenu,
        mobilemenu: _ctx.getMenu,
        "empty-search": "",
        "logo-url": "/",
        "logo-src": 
        require(`@/assets/Lunds_${
          _ctx.$root.$i18n.locale === 'sv' ? 'universitet' : 'university'
        }.svg`)
      ,
        "logo-title": _ctx.$t('shared.lunds-university'),
        showMobileMenu: _ctx.showMobileMenu,
        showLogin: _ctx.showLogin,
        compact: ""
      }, null, 8, ["topmenu", "mobilemenu", "logo-src", "logo-title", "showMobileMenu", "showLogin"]),
      _createVNode(_component_BreadCrumbs),
      _createVNode(_component_LuMain, {
        compact: "",
        class: "flex-grow-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      (_ctx.showFooter)
        ? (_openBlock(), _createBlock(_component_LuFooter, {
            key: 0,
            footerContent: _ctx.footerContent
          }, null, 8, ["footerContent"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}