import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal-dialog my-0 mx-auto",
  role: "document"
}
const _hoisted_2 = { class: "modal-content border-0 rounded-0" }
const _hoisted_3 = { class: "nav border-bottom p-3 flex-row justify-content-between align-items-center sticky-top bg-white" }
const _hoisted_4 = {
  id: "modal-header",
  class: "h3 m-0"
}
const _hoisted_5 = ["aria-expanded", "aria-label"]
const _hoisted_6 = { "aria-hidden": "true" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Backdrop = _resolveComponent("Backdrop")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_Backdrop, { onClickBackdrop: _ctx.onClick }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("nav", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "header")
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "border-0 bg-transparent cursor-pointer lh-0 p-2 nm-2",
                "data-dismiss": "modal",
                "aria-expanded": _ctx.shown,
                "aria-label": _ctx.$t('modal.close'),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "fa-lg",
                    icon: 'times'
                  })
                ])
              ], 8, _hoisted_5)
            ]),
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ]),
      _: 3
    }, 8, ["onClickBackdrop"]), [
      [_vShow, _ctx.shown]
    ])
  ]))
}