
import { Options, Vue } from "vue-class-component";
import LocalStorageService from "@/services/localstorage-service";
import { Constants } from "@/constants/constants";

@Options({
  methods: {
    switchLocale() {
      this.$root.$i18n.locale = this.$root.$i18n.locale === "sv" ? "en" : "sv";
      document.getElementsByTagName("html")[0].lang = this.$root.$i18n.locale;
      LocalStorageService.setItem(Constants.Language, this.$root.$i18n.locale);
      this.$emit("link-selected");
    },
  },
})
export default class LuNavMobileItemChangeLanguage extends Vue {}
