import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group input-group-round input-group-sm w-100 flex" }
const _hoisted_2 = ["value", "placeholder", "aria-label"]
const _hoisted_3 = { class: "input-group-append" }
const _hoisted_4 = {
  class: "btn btn-primary px-2",
  type: "submit"
}
const _hoisted_5 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("form", {
    class: "form-inline pr-xl-3",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('submit', _ctx.value)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        ref: "searchInput",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event.target.value))),
        value: _ctx.value,
        type: "search",
        class: "form-control form-control-sm border-right-0",
        placeholder: _ctx.placeholder,
        "aria-label": _ctx.ariaLabel ? _ctx.ariaLabel : _ctx.$t('shared.search')
      }, null, 40, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("form.search")), 1),
          _createVNode(_component_font_awesome_icon, { icon: 'search' })
        ])
      ])
    ])
  ], 32))
}