
import { Options, Vue } from "vue-class-component";
import LuTopMenuItem from "@/components/Lu/LuTopMenuItem.vue";
import { PropType } from "@vue/runtime-core";
import type { IMenuItem } from "@/interfaces/IMenuItem";

@Options({
  components: {
    LuTopMenuItem,
  },
  props: {
    /**
     * The items to show
     */
    menu: { type: Array as PropType<IMenuItem[]>, required: true },
  },
})
export default class LuTopMenu extends Vue {}
