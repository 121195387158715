import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f25e875"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      href: "#",
      class: "nav-link",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.switchLocale && _ctx.switchLocale(...args)), ["prevent"]))
    }, [
      _createVNode(_component_font_awesome_icon, {
        "aria-hidden": "true",
        icon: 'globe'
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("header.select-locale.change-language")), 1)
    ])
  ]))
}