import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 1,
  class: "nav-item dropdown dropdown-hover"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  class: "dropdown-menu dropdown-menu-right text-primary",
  "aria-labelledby": "sm-lu-fakulteter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_LuDropdownItem = _resolveComponent("LuDropdownItem")!

  return (!_ctx.item.children)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        (_ctx.item.path || _ctx.item.name)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {
        ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
        ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
      },
              class: "nav-link px-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "nav-link px-3",
              href: _ctx.item.url
            }, _toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text), 9, _hoisted_2))
      ]))
    : (_openBlock(), _createElementBlock("li", _hoisted_3, [
        (_ctx.item.path || _ctx.item.name)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {
        ...(_ctx.item.path ? { path: _ctx.item.path } : {}),
        ...(_ctx.item.name ? { name: _ctx.item.name } : {}),
      },
              class: "nav-link dropdown-toggle px-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text) + "  ", 1),
                _createVNode(_component_fa_icon, { icon: ['fal', 'chevron-down'] })
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "nav-link dropdown-toggle px-3",
              href: _ctx.item.url,
              "aria-haspopup": "true"
            }, [
              _createTextVNode(_toDisplayString(_ctx.item.label ? _ctx.$t(_ctx.item.label) : _ctx.item.text) + "  ", 1),
              _createVNode(_component_fa_icon, { icon: ['fal', 'chevron-down'] })
            ], 8, _hoisted_4)),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (subItem) => {
            return (_openBlock(), _createBlock(_component_LuDropdownItem, {
              key: subItem.id,
              item: subItem
            }, null, 8, ["item"]))
          }), 128))
        ])
      ]))
}