
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * Href attribute for the badge
     */
    href: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * Color of the badge
     */
    color: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * Backgroundcolor of the badge
     */
    backgroundColor: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The level of the badge
     * @values primary, secondary, success, danger, warning, info, light, dark
     */
    level: {
      type: String as PropType<
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "light"
        | "dark"
      >,
      required: false,
      default: "primary",
    },
    /**
     * If the badge is rounded
     */
    rounded: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  computed: {
    c_level(): string {
      return `badge-${this.level}`;
    },
    elementType(): string {
      return this.href ? "a" : "span";
    },
  },
})
export default class Badge extends Vue {}
