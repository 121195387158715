
import { computed } from "vue";
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import LocalStorageService from "@/services/localstorage-service";
import { Constants } from "@/constants/constants";
import i18n from "@/utilities/i18n";
import type { IFooterColumn } from "@/interfaces/IFooterColumn";

const {
  global: { t },
} = i18n;

@Options({
  props: {
    /**
     * List of footer columns to display
     */
    footerContent: {
      type: Array as PropType<IFooterColumn[]>,
      required: false,
    },
  },
  computed: {
    locale(): string {
      return this.$root.$i18n.locale;
    },
    logoAlt(): string {
      return computed(() => t("shared.lunds-university")).value;
    },
  },
  methods: {
    switchLocale(): void {
      this.$root.$i18n.locale = this.$root.$i18n.locale === "sv" ? "en" : "sv";
      document.getElementsByTagName("html")[0].lang = this.$root.$i18n.locale;
      LocalStorageService.setItem(Constants.Language, this.$root.$i18n.locale);
    },
    /**
     * Translates the input string using the current locale
     */
    translate(localeKey: string): string {
      return computed(() => t(localeKey)).value;
    },
  },
})
export default class LuFooter extends Vue {}
