import type { IMenuItem } from "@/interfaces/IMenuItem";
import type { IState } from "@/interfaces/IState";
import type { IFooterColumn } from "@/interfaces/IFooterColumn";
import { LinkType } from "@/enums/LinkType";
import { IUser } from "@/interfaces/IUser";

export default {
  getCurrentUser(state: IState): IUser | undefined {
    return state.currentUser;
  },

  getMenu(state: IState): IMenuItem[] {
    const isLoggedIn = !!state.currentUser;
    const menu: IMenuItem[] = [
      {
        id: "mentor-support",
        label: "shared.routes.mentorship",
      },
      {
        id: "note-support",
        label: "shared.routes.notetaking-support",
        ...((!isLoggedIn || state.currentUser?.checkEntitlement("Student")) && {
          name: "NoteTakingRequest",
        }),
        // ...(state.currentUser?.checkEntitlement([
        //   ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        //   "or",
        //   "Samordnare",
        // ]) && {
        //   badge: state.menuBadges["note-support"],
        // }),
        ...(state.currentUser?.checkEntitlement([
          ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
          "or",
          ["Samordnare", "or", "Läsbehörighet Anteckningsstöd"],
        ]) && {
          children: [
            ...(state.currentUser?.checkEntitlement([
              [
                "Anteckningsstödsadministratör",
                "or",
                "Anteckningsadministratör",
              ],
              "or",
              "Samordnare",
            ])
              ? [
                  {
                    id: "note-taker-list",
                    label: "shared.routes.note-taker-overview",
                    name: "NoteTakerList",
                  },
                  {
                    id: "student-list",
                    label: "shared.routes.student-overview",
                    name: "StudentList",
                  },
                ]
              : []),
            ...(state.currentUser?.checkEntitlement([
              [
                "Anteckningsstödsadministratör",
                "or",
                "Anteckningsadministratör",
              ],
              "or",
              ["Samordnare", "or", "Läsbehörighet Anteckningsstöd"],
            ])
              ? [
                  {
                    id: "course-occurrence-overview",
                    label: "shared.routes.course-occurrence-overview",
                    name: "CourseOccurrenceList",
                  },
                ]
              : []),
          ],
        }),
      },
      {
        id: "tuition-fee",
        label: "shared.routes.tuition-fees",
        // ...(state.currentUser?.checkEntitlement([
        //   ["Studieavgiftsadministratör",
        // ]) &&
        // {
        ...(state.currentUser?.checkEntitlement(
          "Tuition fees administratör"
        ) && {
          children: [
            {
              id: "mirror-user",
              label: "tuition-fees.mirror-user",
              name: "MirrorUser",
            },
          ],
          // }),
        }),
      },
      {
        id: "refund",
        label: "shared.routes.repayment",
      },
      {
        id: "suspension",
        label: "shared.routes.suspension",
      },
      {
        id: "application-for-deferment",
        label: "shared.routes.postponement",
      },
    ];

    return menu;
  },

  getTuitionFeesFooterContent(): IFooterColumn[] {
    const luWebsite = "https://www.lunduniversity.lu.se";

    return [
      {
        columnNumber: 1,
        title: "tuition-fees-footer.column-1.title",
        links: [
          {
            linkType: LinkType.External,
            linkLabel:
              "For newly admitted students at Lund University international website",
            url: `${luWebsite}/admissions/bachelors-and-masters-studies/applying-studies-when-apply/tuition-fees`,
          },
          {
            linkType: LinkType.External,
            linkLabel:
              "For current students at Lund University international website",
            url: `${luWebsite}/current-students/financial-matters/tuition-payments-during-studies`,
          },
          {
            linkType: LinkType.External,
            linkLabel: "Lund University refund policy",
            url: `${luWebsite}/admissions/bachelors-and-masters-studies/applying-studies-when-apply/tuition-fees/tuition-fee-refund-policy`,
          },
        ],
      },
      {
        columnNumber: 2,
        title: "tuition-fees-footer.column-2.title",
        links: [
          {
            linkType: LinkType.Email,
            linkLabel: "tuitionfees@eken.lu.se",
            url: "tuitionfees@eken.lu.se",
            leadingText: "The Tuition Fee Office at ",
          },
          {
            linkType: LinkType.External,
            linkLabel: "Other contacts",
            url: `${luWebsite}/about-university/contact-us`,
          },
        ],
      },
      {
        columnNumber: 3,
        title: "tuition-fees-footer.column-3.title",
        links: [
          {
            linkType: LinkType.External,
            linkLabel: "Accessibility Statement",
            url: `${luWebsite}/accessibilitystatement`,
          },
        ],
      },
    ];
  },

  getStudentPortalFooterContent(): IFooterColumn[] {
    return [
      {
        columnNumber: 1,
        title: "student-portal-footer.column-1.title",
        links: [
          {
            linkType: LinkType.Email,
            linkLabel: "servicedesk@lu.se",
            url: "servicedesk@lu.se",
            leadingText: "LU Servicedesk",
          },
        ],
      },
    ];
  },
};
